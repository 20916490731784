import { redirectToHomeOnLoggedIn } from "./redirect";

const vendor = [
	/* vendor */
	{
		path: "/vendor/profile",
		component: () => import("@/pages/accounts/profile"),
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "Profile",
		},
	},
	{
		path: "/vendor/pending",
		component: () => import("@/pages/vendor/waiting"),
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "Dashboard",
		},
	},
	{
		path: "/vendor/dashboard",
		name: "dashboard",
		component: () => import("@/pages/vendor/dashboard"),
		beforeEnter: redirectToHomeOnLoggedIn,
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "Dashboard",
		},
	},
	{
		path: "/vendor/messages",
		component: () => import("@/pages/common/message"),
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "Messages",
		},
	},
	{
		path: "/vendor/purchase-orders",
		component: () => import("@/pages/vendor/orders/all"),
		beforeEnter: redirectToHomeOnLoggedIn,
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "Purchase Orders",
		},
	},
	{
		path: "/vendor/purchase-orders/:id/view",
		component: () => import("@/pages/vendor/orders/view"),
		beforeEnter: redirectToHomeOnLoggedIn,
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "Purchase Order",
		},
	},
	{
		path: "/vendor/purchase-orders/:id/create-delivery-note",
		component: () => import("@/pages/vendor/deliveries/add"),
		beforeEnter: redirectToHomeOnLoggedIn,
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "Create Delivery Note",
		},
	},
	{
		path: "/vendor/purchase-orders/:id/delivery-notes",
		component: () => import("@/pages/vendor/orders/deliveries"),
		beforeEnter: redirectToHomeOnLoggedIn,
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "LPO D.Notes",
		},
	},
	{
		path: "/vendor/purchase-orders/:id/invoices",
		component: () => import("@/pages/vendor/orders/invoices"),
		beforeEnter: redirectToHomeOnLoggedIn,
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "LPO Invoices",
		},
	},
	{
		path: "/vendor/deliveries",
		component: () => import("@/pages/vendor/deliveries/all"),
		beforeEnter: redirectToHomeOnLoggedIn,
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "Deliveries & D. Notes",
		},
	},
	{
		path: "/vendor/deliveries/:id/view",
		component: () => import("@/pages/vendor/deliveries/view"),
		beforeEnter: redirectToHomeOnLoggedIn,
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "Delivery Note",
		},
	},
	{
		path: "/vendor/rfx-events",
		component: () => import("@/pages/vendor/rfx/all"),
		beforeEnter: redirectToHomeOnLoggedIn,
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "RFx Events",
		},
	},
	{
		path: "/vendor/rfx-events/:id/view",
		component: () => import("@/pages/vendor/rfx/view"),
		beforeEnter: redirectToHomeOnLoggedIn,
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "RFx Event Details",
		},
	},
	{
		path: "/vendor/auctions",
		component: () => import("@/pages/vendor/auctions/all"),
		beforeEnter: redirectToHomeOnLoggedIn,
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "Auctions",
		},
	},
	{
		path: "/vendor/auctions/:id/view",
		component: () => import("@/pages/vendor/auctions/view"),
		beforeEnter: redirectToHomeOnLoggedIn,
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "Auction Details",
		},
	},
	{
		path: "/vendor/auctions/:id/livestream",
		component: () => import("@/pages/vendor/auctions/livestream"),
		beforeEnter: redirectToHomeOnLoggedIn,
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "Auction Live Bidding",
		},
	},
	{
		path: "/vendor/contracts",
		component: () => import("@/pages/vendor/contracts/all"),
		beforeEnter: redirectToHomeOnLoggedIn,
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "Contracts",
		},
	},
	{
		path: "/vendor/contracts/:id/view",
		component: () => import("@/pages/vendor/contracts/view"),
		beforeEnter: redirectToHomeOnLoggedIn,
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "Contract Details",
		},
	},
	{
		path: "/vendor/invoices",
		component: () => import("@/pages/vendor/invoices/all"),
		beforeEnter: redirectToHomeOnLoggedIn,
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "Invoices",
		},
	},
	{
		path: "/vendor/invoices/:id/view",
		component: () => import("@/pages/vendor/invoices/view"),
		beforeEnter: redirectToHomeOnLoggedIn,
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "Invoice Details",
		},
	},
	{
		path: "/vendor/catalogue",
		component: () => import("@/pages/vendor/catalogue"),
		beforeEnter: redirectToHomeOnLoggedIn,
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "Invoices",
		},
	},
	{
		path: "/vendor/users",
		component: () => import("@/pages/vendor/users/all"),
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "User Accounts",
		},
	},
	{
		path: "/vendor/users/create",
		component: () => import("@/pages/vendor/users/add"),
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "Create Account",
		},
	},
	{
		path: "/vendor/users/:id/view",
		component: () => import("@/pages/vendor/users/view"),
		meta: {
			layout: "loggedInVendor",
			requireAuth: true,
			title: "User Account",
		},
	},
];

export default vendor;
