import { redirectToHomeOnLoggedIn } from "./redirect";

const auth = [
	/* accounts */
	{
		path: "/",
		component: () => import("@/pages/accounts/login"),
		meta: { title: "Login" },
	},
	{
		path: "/accounts/:token/reset-password",
		component: () => import("@/pages/accounts/reset"),
		meta: { title: "Reset Password" },
	},
	{
		path: "/accounts/forgot-password",
		component: () => import("@/pages/accounts/forgot"),
		meta: { title: "Forgot Password" },
	},
	{
		path: "/accounts/register",
		name: "accounts-register",
		component: () => import("@/pages/accounts/register"),
	},
	{
		path: "/accounts/logout",
		component: () => import("@/pages/accounts/logout"),
		meta: { requireAuth: true, title: "Please wait..." },
	},
];

export default auth;
