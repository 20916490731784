import { useAuthStore } from "@/store/useAuth";

export const redirectToHomeOnLoggedIn = (to, from, next) => {
	if (useAuthStore().loggedIn) {
		if (
			!["/vendor/pending", "/vendor/messages"].includes(to.path) &&
			!useAuthStore().user?.isActivated &&
			useAuthStore().user?.role.code === "SSS-A"
		) {
			next({ path: "/vendor/pending" });
		} else next();
	} else next();
};
