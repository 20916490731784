const agency = [
	/* agency */
	{
		path: "/agency/profile",
		component: () => import("@/pages/accounts/profile"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Profile",
		},
	},
	{
		path: "/agency/dashboard",
		component: () => import("@/pages/agency/dashboard"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Dashboard",
		},
	},
	{
		path: "/agency/notifications",
		component: () => import("@/pages/common/notification"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Notifications",
		},
	},
	{
		path: "/agency/messages",
		component: () => import("@/pages/common/message"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Messages",
		},
	},
	{
		path: "/agency/supplier-management",
		component: () => import("@/pages/agency/suppliers/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Supplier Management",
		},
	},
	{
		path: "/agency/supplier-management/:id",
		component: () => import("@/pages/agency/suppliers/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Supplier Information",
		},
	},
	{
		path: "/agency/settings/branches",
		component: () => import("@/pages/agency/settings/branches/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Branches",
		},
	},
	{
		path: "/agency/settings/branches/create",
		component: () => import("@/pages/agency/settings/branches/add"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Add Branch",
		},
	},
	{
		path: "/agency/settings/branches/:id/view",
		component: () => import("@/pages/agency/settings/branches/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Branch Details",
		},
	},
	{
		path: "/agency/settings/departments",
		component: () => import("@/pages/agency/settings/departments/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Departments",
		},
	},
	{
		path: "/agency/settings/departments/create",
		component: () => import("@/pages/agency/settings/departments/add"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Add Department",
		},
	},
	{
		path: "/agency/settings/departments/:id/view",
		component: () => import("@/pages/agency/settings/departments/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Department Details",
		},
	},
	{
		path: "/agency/settings/sectors",
		component: () => import("@/pages/agency/settings/sectors/all"),
		meta: { layout: "loggedInAgency", requireAuth: true, title: "Sectors" },
	},
	{
		path: "/agency/settings/sectors/create",
		component: () => import("@/pages/agency/settings/sectors/add"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Add Sector",
		},
	},
	{
		path: "/agency/settings/sectors/:id/view",
		component: () => import("@/pages/agency/settings/sectors/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Sector Details",
		},
	},
	{
		path: "/agency/settings/general-ledger",
		component: () => import("@/pages/agency/settings/gl/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "General Ledger",
		},
	},
	{
		path: "/agency/settings/general-ledger/create",
		component: () => import("@/pages/agency/settings/gl/add"),
		meta: { layout: "loggedInAgency", requireAuth: true, title: "Add GL" },
	},
	{
		path: "/agency/settings/general-ledger/:id/view",
		component: () => import("@/pages/agency/settings/gl/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "GL Details",
		},
	},
	{
		path: "/agency/settings/users",
		component: () => import("@/pages/agency/settings/users/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "User Accounts",
		},
	},
	{
		path: "/agency/settings/users/create",
		component: () => import("@/pages/agency/settings/users/add"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Create Account",
		},
	},
	{
		path: "/agency/settings/users/:id/view",
		component: () => import("@/pages/agency/settings/users/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "User Account",
		},
	},
	{
		path: "/agency/settings/roles",
		component: () => import("@/pages/agency/settings/roles/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "User Roles",
		},
	},
	{
		path: "/agency/settings/roles/:id/view",
		component: () => import("@/pages/agency/settings/roles/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "User Role",
		},
	},
	{
		path: "/agency/settings/notifications",
		component: () => import("@/pages/agency/settings/notifications/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Notification Settings",
		},
	},
	{
		path: "/agency/settings/notifications/:id/view",
		component: () => import("@/pages/agency/settings/notifications/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Notification Details",
		},
	},
	{
		path: "/agency/settings/miscellaneous",
		component: () => import("@/pages/agency/settings/miscellaneous/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Miscellaneous",
		},
	},
	{
		path: "/agency/settings/company-settings",
		component: () => import("@/pages/agency/settings/company-info"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Company Information",
		},
	},
	{
		path: "/agency/purchasing/requisitions",
		component: () => import("@/pages/agency/reqs/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Requisitions",
		},
	},
	{
		path: "/agency/purchasing/create-requisition",
		component: () => import("@/pages/agency/reqs/add"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Create Requisition",
		},
	},
	{
		path: "/agency/purchasing/requisitions/:id/view",
		component: () => import("@/pages/agency/reqs/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Requisition Details",
		},
	},
	{
		path: "/agency/strategic-e-sourcing/rfx-events",
		component: () => import("@/pages/agency/rfx/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "RFx",
		},
	},
	{
		path: "/agency/strategic-e-sourcing/rfx-events/create",
		component: () => import("@/pages/agency/rfx/add"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Create RFx",
		},
	},
	{
		path: "/agency/strategic-e-sourcing/rfx-events/:id/view",
		component: () => import("@/pages/agency/rfx/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "RFx Details",
		},
	},
	{
		path: "/agency/strategic-e-sourcing/rfx-events/:id/responses",
		component: () => import("@/pages/agency/rfx/responses"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "RFx Responses",
		},
	},
	{
		path: "/agency/strategic-e-sourcing/auctions",
		component: () => import("@/pages/agency/auction/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Auctions",
		},
	},
	{
		path: "/agency/strategic-e-sourcing/auctions/create",
		component: () => import("@/pages/agency/auction/add"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Create Auction",
		},
	},
	{
		path: "/agency/strategic-e-sourcing/auctions/:id/view",
		component: () => import("@/pages/agency/auction/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Auction Details",
		},
	},
	{
		path: "/agency/strategic-e-sourcing/auctions/:id/livestream",
		component: () => import("@/pages/agency/auction/livestream"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Auction Bidding",
		},
	},
	{
		path: "/agency/strategic-e-sourcing/reverse-auctions",
		component: () => import("@/pages/agency/r-auction/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Reverse Auctions",
		},
	},
	{
		path: "/agency/strategic-e-sourcing/reverse-auctions/create",
		component: () => import("@/pages/agency/r-auction/add"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Create Reverse Auction",
		},
	},
	{
		path: "/agency/strategic-e-sourcing/reverse-auctions/:id/view",
		component: () => import("@/pages/agency/r-auction/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Reverse Auction Details",
		},
	},
	{
		path: "/agency/strategic-e-sourcing/reverse-auctions/:id/livestream",
		component: () => import("@/pages/agency/r-auction/livestream"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Reverse Auction Bidding",
		},
	},
	{
		path: "/agency/purchasing/purchase-orders",
		component: () => import("@/pages/agency/lpos/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Purchase Orders",
		},
	},
	{
		path: "/agency/purchasing/purchase-orders/:id/view",
		component: () => import("@/pages/agency/lpos/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Purchase Order Details",
		},
	},
	{
		path: "/agency/purchasing/purchase-orders/create",
		component: () => import("@/pages/agency/lpos/add"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Create LPO",
		},
	},
	{
		path: "/agency/contracts",
		component: () => import("@/pages/agency/contacts/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Contracts",
		},
	},
	{
		path: "/agency/contracts/:id/view",
		component: () => import("@/pages/agency/contacts/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Contract Details",
		},
	},
	{
		path: "/agency/contracts/create",
		component: () => import("@/pages/agency/contacts/add"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "New Contract",
		},
	},
	{
		path: "/agency/deliveries-inspection",
		component: () => import("@/pages/agency/deliveries/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Delivery & Inspection",
		},
	},
	{
		path: "/agency/deliveries-inspection/:id/view",
		component: () => import("@/pages/agency/deliveries/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Delivery Details",
		},
	},
	{
		path: "/agency/invoice-management",
		component: () => import("@/pages/agency/invoices/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Invoices",
		},
	},
	{
		path: "/agency/invoice-management/:id/view",
		component: () => import("@/pages/agency/invoices/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Invoice Details",
		},
	},
	{
		path: "/agency/approvals",
		component: () => import("@/pages/agency/approvals/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Approvals",
		},
	},
	{
		path: "/agency/budgets",
		component: () => import("@/pages/agency/budgets/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Budgets",
		},
	},
	{
		path: "/agency/budgets/:id/view",
		component: () => import("@/pages/agency/budgets/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Budgets",
		},
	},
	{
		path: "/agency/budgets/create",
		component: () => import("@/pages/agency/budgets/add"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "New Budget",
		},
	},
	{
		path: "/agency/inventory/item-master",
		component: () => import("@/pages/agency/inventory/item-master/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Items Master",
		},
	},
	{
		path: "/agency/inventory/item-master/:id/view",
		component: () => import("@/pages/agency/inventory/item-master/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "View Catalogue Item",
		},
	},
	{
		path: "/agency/inventory/item-master/create",
		component: () => import("@/pages/agency/inventory/item-master/add"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Create Catalogue Item",
		},
	},
	{
		path: "/agency/inventory/stock-adjustments",
		component: () =>
			import("@/pages/agency/inventory/stock-adjustment/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Stock Adjustments",
		},
	},
	{
		path: "/agency/inventory/stock-adjustments/create",
		component: () =>
			import("@/pages/agency/inventory/stock-adjustment/add"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Add Stock Adjustment",
		},
	},
	{
		path: "/agency/inventory/stock-adjustments/:id/view",
		component: () =>
			import("@/pages/agency/inventory/stock-adjustment/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Stock Adjustment Details",
		},
	},
	{
		path: "/agency/inventory/stock-freezes",
		component: () => import("@/pages/agency/inventory/stock-freeze/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Stock Freezes",
		},
	},
	{
		path: "/agency/inventory/stock-freezes/create",
		component: () => import("@/pages/agency/inventory/stock-freeze/add"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Freeze Stock",
		},
	},
	{
		path: "/agency/inventory/stock-freezes/:id/view",
		component: () => import("@/pages/agency/inventory/stock-freeze/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Stock Freeze Details",
		},
	},
	{
		path: "/agency/inventory/stock-query",
		component: () => import("@/pages/agency/inventory/stock-query/search"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Stock Query",
		},
	},
	{
		path: "/agency/inventory/item-issuance",
		component: () => import("@/pages/agency/inventory/item-issuance/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Item Issuance",
		},
	},
	{
		path: "/agency/inventory/item-issuance/create",
		component: () => import("@/pages/agency/inventory/item-issuance/add"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Item Issuances",
		},
	},
	{
		path: "/agency/inventory/item-issuance/:id/view",
		component: () => import("@/pages/agency/inventory/item-issuance/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Item Issuance Details",
		},
	},
	{
		path: "/agency/inventory/item-transfer",
		component: () => import("@/pages/agency/inventory/item-transfer/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Item Transfers",
		},
	},
	{
		path: "/agency/inventory/item-transfer/create",
		component: () => import("@/pages/agency/inventory/item-transfer/add"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "New Item Transfer",
		},
	},
	{
		path: "/agency/inventory/item-transfer/:id/view",
		component: () => import("@/pages/agency/inventory/item-transfer/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Item Transfer Details",
		},
	},
	{
		path: "/agency/projects",
		component: () => import("@/pages/agency/projects/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Projects",
		},
	},
	{
		path: "/agency/projects/:id/view",
		component: () => import("@/pages/agency/projects/view"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Project Details",
		},
	},
	{
		path: "/agency/projects/create",
		component: () => import("@/pages/agency/projects/add"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Add Project",
		},
	},
	{
		path: "/agency/reports",
		component: () => import("@/pages/agency/report/all"),
		meta: {
			layout: "loggedInAgency",
			requireAuth: true,
			title: "Reports",
		},
	},
];

export default agency
