<template>
  <div class="flex h-screen bg-gray-50" :class=" { 'overflow-hidden': options.isSideMenuOpen } ">
    <!-- Desktop sidebar -->
    <aside class="z-20 hidden overflow-y-auto bg-white md:block flex-shrink-0"
      :class=" [options.isCollapsed ? 'w-24' : 'w-64'] " @mouseenter=" toggleCollapse " @mouseleave=" toggleCollapse ">
      <div class="text-gray-500">
        <router-link to="/agency/dashboard">
          <img src="/favicon.png" class="h-20" />
        </router-link>
        <ul class="mt-6">
          <li class="relative px-6 py-3" v-for="( item, index ) in  menuItems " :key=" `menu-${ index }` ">
            <span class="absolute inset-y-0 left-0 w-2 bg-teal-400 rounded-tr-lg rounded-br-lg" aria-hidden="true"
              v-if=" $route.path == `/agency/${ item.to }` "></span>
            <template v-if=" !item.hasSubMenus ">
              <router-link class="inline-flex items-center w-full text-xs font-semibold transition-colors duration-150"
                :to=" `/agency/${ item.to }` ">
                <component :is="item.icon" class="h-6 w-6 font-bold" aria-hidden="true" />
                <span class="ml-4" :class=" [options.isCollapsed ? 'hidden' : ''] ">{{ item.name }}</span>
              </router-link>
            </template>
            <template v-else>
              <span class="absolute inset-y-0 left-0 w-2 bg-teal-400 rounded-tr-lg rounded-br-lg" aria-hidden="true"
                v-if=" $route.path.includes(`/agency/${ item.id }`) "></span>
              <button
                class="inline-flex items-center justify-between w-full text-xs font-semibold transition-colors duration-150"
                @click="toggleSideMenuItem(item.id)" aria-haspopup="true">
                <span class="inline-flex items-center">
                  <component :is="item.icon" class="h-6 w-6 font-bold" aria-hidden="true" />
                  <span class="ml-4" :class=" [options.isCollapsed ? 'hidden' : ''] ">{{ item.name }}</span>
                </span>
                <ChevronRightIcon v-if=" options.isCollapsed " class="w-4 h-4" />
                <ChevronDownIcon v-else class="w-4 h-4" />
              </button>
              <template v-if=" openSideMenuItem == item.id ">
                <ul enter-active-class="transition-all ease-in-out duration-300" enter-class="opacity-25 max-h-0"
                  enter-to-class="opacity-100 max-h-xl" leave-active-class="transition-all ease-in-out duration-300"
                  leave-class="opacity-100 max-h-xl" leave-to-class="opacity-0 max-h-0"
                  class="p-2 mt-2 space-y-2 overflow-hidden text-xs font-medium text-gray-500 rounded-md shadow-inner bg-gray-50"
                  aria-label="submenu">
                  <li class="px-2 py-1 transition-colors duration-150" v-for="( subItem, index ) in  item.subMenuItems "
                    :key=" `sub-menu-${ index }` ">
                    <router-link class="w-full" :to=" `/agency/${ item.id }/${ subItem.to }` ">
                      <span class="inline-flex items-left">
                        <component :is="subItem.icon" class="h-4 w-4" aria-hidden="true" />
                        <span class="ml-4" :class=" [options.isCollapsed ? 'hidden' : ''] ">{{ subItem.name }}</span>
                      </span>
                    </router-link>
                  </li>
                </ul>
              </template>
            </template>
          </li>
        </ul>
        <div class="px-7 my-5">
          <router-link to="/accounts/logout"
            class="flex items-center w-full justify-between p-2 font-bold leading-5 text-white transition-colors duration-150 border border-transparent"
            :class=" [options.isCollapsed ? 'rounded-full bg-gray-200' : 'rounded-full bg-red-500'] ">
            <span :class=" [options.isCollapsed ? 'hidden' : ''] ">Logout</span>
            <PowerIcon class="h-6 w-6 text-red-500 font-bold" v-if=" options.isCollapsed " />
            <ArrowRightOnRectangleIcon class="h-6 w-6 text-white" aria-hidden="true" v-else />
          </router-link>
        </div>
      </div>
    </aside>

    <!-- Mobile sidebar -->
    <!-- Backdrop -->
    <transition v-show=" options.isSideMenuOpen " enter-active-class="transition ease-in-out duration-150"
      enter-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition ease-in-out duration-150"
      leave-class="opacity-100" leave-to-class="opacity-0"
      class="fixed inset-0 z-10 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div class="fixed inset-0 z-10 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"></div>
    </transition>
    <transition v-show=" options.isSideMenuOpen " enter-active-class="transition ease-in-out duration-150"
      enter-class="opacity-0 transform -translate-x-20" enter-to-class="opacity-100"
      leave-active-class="transition ease-in-out duration-150" leave-class="opacity-100"
      leave-to-class="opacity-0 transform -translate-x-20"
      class="fixed inset-y-0 z-20 flex-shrink-0 w-64 mt-16 overflow-y-auto bg-white dark:bg-gray-800 md:hidden"
      @click=" closeSideMenu " @keydown.escape=" closeSideMenu ">
      <div>
        <router-link to="/agency/dashboard">
          <img src="/favicon.png" class="h-20" />
        </router-link>
        <ul class="mt-6">
          <li class="relative px-6 py-3" v-for="( item, index ) in  mobileMenu " :key=" `m-menu-${ index }` ">
            <span class="absolute inset-y-0 left-0 w-2 bg-teal-400 rounded-tr-lg rounded-br-lg" aria-hidden="true"
              v-if=" $route.path == `/agency/${ item.to }` "></span>
            <router-link class="inline-flex items-center w-full text-xs font-semibold transition-colors duration-150"
              :to=" `/agency/${ item.to }` ">
              <component :is="item.icon" class="h-6 w-6 font-bold" aria-hidden="true" />
              <span class="ml-4">{{ item.name }}</span>
            </router-link>
          </li>
        </ul>
        <div class="px-3 my-6">
          <router-link to="/accounts/logout"
            class="flex items-center justify-between w-full px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-teal-600 border border-transparent rounded-lg active:bg-teal-600 hover:bg-teal-700 focus:outline-none focus:shadow-outline-teal">
            Logout
            <LockClosedIcon class="h-5 w-5 text-white" aria-hidden="true" />
          </router-link>
        </div>
      </div>
    </transition>
    <div class="flex flex-col flex-1 w-full">
      <header class="z-10 py-4 bg-white shadow-md dark:bg-gray-800">
        <div class="container flex items-center justify-between h-full px-6 mx-auto text-teal-600 dark:text-teal-300">
          <!-- Mobile hamburger -->
          <button class="p-1 mr-5 -ml-1 rounded-md md:hidden focus:outline-none focus:shadow-outline-teal"
            @click=" toggleSideMenu " aria-label="Menu">
            <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"></path>
            </svg>
          </button>
          <!-- Search input -->
          <div class="flex justify-center flex-1 lg:mr-32">
            <div class="relative w-full max-w-xl mr-6 focus-within:text-teal-500">
              <div class="absolute inset-y-0 flex items-center pl-2">
                <svg class="w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"></path>
                </svg>
              </div>
              <input
                class="w-full pl-8 pr-2 text-sm text-gray-700 placeholder-gray-600 bg-gray-100 border-0 rounded-md dark:placeholder-gray-500 dark:focus:shadow-outline-gray dark:focus:placeholder-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:placeholder-gray-500 focus:bg-white focus:border-teal-300 focus:outline-none focus:shadow-outline-teal form-input"
                type="text" placeholder="Search here..." aria-label="Search" />
            </div>
          </div>
          <ul class="flex items-center flex-shrink-0 space-x-6">
            <!-- Notifications menu -->
            <li class="relative">
              <router-link to="/agency/messages"
                class="relative align-middle rounded-md focus:outline-none focus:shadow-outline-teal"
                aria-haspopup="true">
                <BellIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
                <!-- Notification badge -->
                <span aria-hidden="true"
                  class="absolute top-0 right-0 inline-block w-3 h-3 transform translate-x-1 -translate-y-1 bg-red-600 border-2 border-white rounded-full dark:border-gray-800"></span>
              </router-link>
            </li>
            <!-- Messages menu -->
            <li class="relative">
              <router-link to="/agency/messages"
                class="relative align-middle rounded-md focus:outline-none focus:shadow-outline-teal"
                aria-haspopup="true">
                <EnvelopeIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
              </router-link>
            </li>
            <!-- Profile menu -->
            <li class="relative">
              <button class="align-middle rounded-full focus:shadow-outline-teal focus:outline-none"
                @click=" toggleProfileMenu " @keydown.escape=" closeProfileMenu " aria-label="Account" aria-haspopup="true">
                <img class="object-cover w-8 h-8 rounded-full" :src=" userInfo?.avatar ? `${ baseUrl }/media/profile/${ userInfo?.avatar }` : '/no-photo.jpg' "
                    crossorigin="anonymous" :alt=" userInfo?.email " loading="lazy"
                  aria-hidden="true" />
              </button>
              <template v-if=" options.isProfileMenuOpen ">
                <ul x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
                  x-transition:leave-end="opacity-0" @click=" closeProfileMenu " @keydown.escape=" closeProfileMenu "
                  class="absolute right-0 w-56 p-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md dark:border-gray-700 dark:text-gray-300 dark:bg-gray-700"
                  aria-label="submenu">
                  <li class="flex">
                    <router-link
                      class="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                      to="/agency/profile">
                      <svg class="w-4 h-4 mr-3" aria-hidden="true" fill="none" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                        <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                      </svg>
                      <span>Profile</span>
                    </router-link>
                  </li>
                  <li class="flex">
                    <router-link
                      class="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                      to="/accounts/logout">
                      <svg class="w-4 h-4 mr-3" aria-hidden="true" fill="none" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                        <path
                          d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1">
                        </path>
                      </svg>
                      <span>Log out</span>
                    </router-link>
                  </li>
                </ul>
              </template>
            </li>
          </ul>
        </div>
      </header>
      <slot />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useAuthStore } from "@/store/useAuth";
import {
  HomeIcon,
  RectangleStackIcon,
  LockClosedIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  InformationCircleIcon,
  UserCircleIcon,
  MapPinIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  TruckIcon,
  Cog8ToothIcon,
  CheckBadgeIcon,
  EnvelopeIcon,
  DocumentIcon,
  DocumentTextIcon,
  DocumentDuplicateIcon,
  BriefcaseIcon,
  ShoppingBagIcon,
  BanknotesIcon,
  ScaleIcon,
  ClipboardDocumentIcon,
  RectangleGroupIcon,
  ServerIcon,
  SquaresPlusIcon,
  Squares2X2Icon,
  QrCodeIcon,
  WalletIcon,
  PresentationChartLineIcon,
  PowerIcon,
  SwatchIcon,
  ArrowRightOnRectangleIcon,
  ArrowUpOnSquareStackIcon,
  NewspaperIcon,
  MagnifyingGlassCircleIcon,
  BellIcon
} from '@heroicons/vue/24/outline'

const router = useRouter();
const authStore = useAuthStore()
const { userInfo } = storeToRefs(authStore)
const baseUrl = process.env.VUE_APP_API_URL

const options = reactive({
  sidebarOpen: false,
  isSideMenuOpen: false,
  purchasing: false,
  deliveries: false,
  isProfileMenuOpen: false,
  isCollapsed: true,
})

const openSideMenuItem = ref('')

const toggleSideMenu = () => options.isSideMenuOpen = !options.isSideMenuOpen
const closeSideMenu = () => options.isSideMenuOpen = false
const toggleProfileMenu = () => options.isProfileMenuOpen = !options.isProfileMenuOpen
const closeProfileMenu = () => options.isProfileMenuOpen = false
const toggleCollapse = () => options.isCollapsed = !options.isCollapsed

const toggleSideMenuItem = (sideMenuItem) => {
  openSideMenuItem.value = openSideMenuItem.value == sideMenuItem ? '' : sideMenuItem
  options[sideMenuItem] = !options[sideMenuItem]
}

/* menu items */
const menuItems = [
  { name: 'Dashboard', icon: HomeIcon, to: 'dashboard', hasSubMenus: false },
  {
    id: 'purchasing', name: 'Purchasing', icon: NewspaperIcon, hasSubMenus: true,
    subMenuItems: [
      { name: 'Requisitions', to: 'requisitions', icon: DocumentIcon },
      { name: 'Purchase Orders', to: 'purchase-orders', icon: DocumentTextIcon },
    ]
  },
  {
    id: 'strategic-e-sourcing', name: 'Strategic e-Sourcing', icon: BriefcaseIcon, hasSubMenus: true,
    subMenuItems: [
      { name: 'RFx Events', to: 'rfx-events', icon: ShoppingBagIcon },
      { name: 'Auctions', to: 'auctions', icon: ScaleIcon },
      { name: 'Reverse Auctions', to: 'reverse-auctions', icon: SquaresPlusIcon },
    ]
  },
  { name: 'Contracts', icon: CheckBadgeIcon, to: 'contracts', hasSubMenus: false },
  { name: 'Deliveries & Inspection', icon: TruckIcon, to: 'deliveries-inspection', hasSubMenus: false },
  { name: 'Invoice Management', icon: ClipboardDocumentIcon, to: 'invoice-management', hasSubMenus: false },
  { name: 'Supplier Management', icon: UserGroupIcon, to: 'supplier-management', hasSubMenus: false },
  { name: 'Approvals', icon: ShieldCheckIcon, to: 'approvals', hasSubMenus: false },
  { name: 'Budgets', icon: BanknotesIcon, to: 'budgets', hasSubMenus: false },
  {
    id: 'inventory', name: 'Inventory', icon: RectangleGroupIcon, hasSubMenus: true,
    subMenuItems: [
      { name: 'Item Master', to: 'item-master', icon: ServerIcon },
      { name: 'Stock Adjustments', to: 'stock-adjustments', icon: SquaresPlusIcon },
      { name: 'Stock Freeze', to: 'stock-freezes', icon: Squares2X2Icon },
      { name: 'Stock Query', to: 'stock-query', icon: MagnifyingGlassCircleIcon },
      { name: 'Material Issuance', to: 'item-issuance', icon: QrCodeIcon },
      { name: 'Material Transfers', to: 'item-transfer', icon: ArrowUpOnSquareStackIcon },
    ]
  },
  { name: 'Projects', icon: WalletIcon, to: 'projects', hasSubMenus: false },
  {
    id: 'settings', name: 'Settings', icon: Cog8ToothIcon, hasSubMenus: true,
    subMenuItems: [
      { name: 'Branches', to: 'branches', icon: MapPinIcon },
      { name: 'Departments', to: 'departments', icon: RectangleGroupIcon },
      { name: 'Sectors', to: 'sectors', icon: DocumentDuplicateIcon },
      { name: 'General Ledger {GL}', to: 'general-ledger', icon: RectangleStackIcon },
      { name: 'Roles', icon: LockClosedIcon, to: 'roles', hasSubMenus: false },
      { name: 'Users', icon: UserCircleIcon, to: 'users', hasSubMenus: false },
      { name: 'Notifications', icon: UserCircleIcon, to: 'notifications', hasSubMenus: false },
      { name: 'Company Settings', icon: InformationCircleIcon, to: 'company-settings', hasSubMenus: false },
      { name: 'Miscellaneous', icon: SwatchIcon, to: 'miscellaneous', hasSubMenus: false },
    ]
  },
  { name: 'Reports', icon: PresentationChartLineIcon, to: 'reports', hasSubMenus: false },
]

const mobileMenu = menuItems.flatMap(v => {
  /* clone inner copy as it references old array-obj */
  const item = JSON.parse(JSON.stringify(v))
  return (!item.hasSubMenus)
    ? item
    :
    item.subMenuItems.flatMap(s => Object.assign(s, { to: `${v.id}/${s.to}`, hasSubMenus: false }))
})

</script>