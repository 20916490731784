<template>
  <div class="flex h-screen bg-gray-50 dark:bg-gray-900" :class="{ 'overflow-hidden': options.isSideMenuOpen }">
    <!-- Desktop sidebar -->
    <aside class="z-20 hidden overflow-y-auto bg-white dark:bg-gray-800 md:block flex-shrink-0" :class="[options.isCollapsed ? 'w-24' : 'w-64']" @mouseenter="toggleCollapse"  @mouseleave="toggleCollapse">
      <div class="text-gray-500 dark:text-gray-400">
        <router-link
          :to="!userInfo?.isActivated ? '/vendor/pending' : '/vendor/dashboard'">
          <img src="/favicon.png" class="h-20" />
        </router-link>
        <ul class="mt-6">
          <li class="relative px-6 py-3" v-for="(item, index) in menuItems" :key="`m-menu-${index}`">
            <span class="absolute inset-y-0 left-0 w-2 bg-teal-400 rounded-tr-lg rounded-br-lg" aria-hidden="true"
              v-if="$route.path == `/vendor/${ item.to }`"></span>
            <router-link
              class="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150"
              :to="`/vendor/${item.to}`">
              <component :is="item.icon" class="h-5 w-5 font-bold" aria-hidden="true" />
              <span class="ml-4" :class="[options.isCollapsed ? 'hidden' : '']">{{item.name}}</span>
            </router-link>
          </li>
        </ul>
        <div class="px-7 my-6">
          <router-link to="/accounts/logout"
            class="flex items-center w-full justify-between p-2 font-bold leading-5 text-white transition-colors duration-150 border border-transparent"
              :class="[options.isCollapsed ? 'rounded-full bg-gray-200' : 'rounded-full bg-red-500']">
            <span :class="[options.isCollapsed ? 'hidden' : '']">Logout</span>
            <PowerIcon class="h-6 w-6 text-red-500 font-bold" v-if="options.isCollapsed" />
            <ArrowRightOnRectangleIcon class="h-6 w-6 text-white" aria-hidden="true" v-else />
          </router-link>
        </div>
      </div>
    </aside>
    <!-- Mobile sidebar -->
    <!-- Backdrop -->
    <transition v-show="options.isSideMenuOpen" enter-active-class="transition ease-in-out duration-150"
      enter-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition ease-in-out duration-150"
      leave-class="opacity-100" leave-to-class="opacity-0"
      class="fixed inset-0 z-10 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
      <div class="fixed inset-0 z-10 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"></div>
    </transition>
    <transition v-show="options.isSideMenuOpen" enter-active-class="transition ease-in-out duration-150"
      enter-class="opacity-0 transform -translate-x-20" enter-to-class="opacity-100"
      leave-active-class="transition ease-in-out duration-150" leave-class="opacity-100"
      leave-to-class="opacity-0 transform -translate-x-20"
      class="fixed inset-y-0 z-20 flex-shrink-0 w-64 mt-16 overflow-y-auto bg-white dark:bg-gray-800 md:hidden"
      @click="closeSideMenu" @keydown.escape="closeSideMenu">
      <div>
        <router-link
          :to="!userInfo?.isActivated ? '/vendor/pending' : '/vendor/dashboard'">
          <img src="/logo.png" class="h-20" />
        </router-link>
        <ul class="mt-6">
          <li class="relative px-6 py-3">
            <router-link
              class="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100"
              :to="!userInfo?.isActivated ? '/vendor/pending' : '/vendor/dashboard'">
              <svg class="w-5 h-5" aria-hidden="true" fill="none" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6">
                </path>
              </svg>
              <span class="ml-4">Dashboard</span>
            </router-link>
          </li>
          <li class="relative px-6 py-3" v-for="(item, index) in menuItems" :key="`m-menu-${index}`">
            <router-link
              class="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
              :to="`/vendor/${item.to}`">
              <component :is="item.icon" class="h-6 w-6 text-gray-500" aria-hidden="true" />
              <span class="ml-4" :class="[options.isCollapsed ? 'hidden' : '']">{{item.name}}</span>
            </router-link>
          </li>
        </ul>
        <div class="px-6 my-6">
          <router-link to="/accounts/logout"
            class="flex items-center justify-between w-full px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-teal-600 border border-transparent rounded-lg active:bg-teal-600 hover:bg-teal-700 focus:outline-none focus:shadow-outline-teal">
            Logout
            <LockClosedIcon class="h-5 w-5 text-white" aria-hidden="true" />
          </router-link>
        </div>
      </div>
    </transition>
    <div class="flex flex-col flex-1 w-full">
      <header class="z-10 py-4 bg-white shadow-md dark:bg-gray-800">
        <div class="container flex items-center justify-between h-full px-6 mx-auto text-teal-600 dark:text-teal-300">
          <!-- Mobile hamburger -->
          <button class="p-1 mr-5 -ml-1 rounded-md md:hidden focus:outline-none focus:shadow-outline-teal"
            @click="toggleSideMenu" aria-label="Menu">
            <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"></path>
            </svg>
          </button>
          <!-- Search input -->
          <div class="flex justify-center flex-1 lg:mr-32">
            <div class="relative w-full max-w-xl mr-6 focus-within:text-teal-500">
              <div class="absolute inset-y-0 flex items-center pl-2">
                <svg class="w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"></path>
                </svg>
              </div>
              <input
                class="w-full pl-8 pr-2 text-sm text-gray-700 placeholder-gray-600 bg-gray-100 border-0 rounded-md dark:placeholder-gray-500 dark:focus:shadow-outline-gray dark:focus:placeholder-gray-600 dark:bg-gray-700 dark:text-gray-200 focus:placeholder-gray-500 focus:bg-white focus:border-teal-300 focus:outline-none focus:shadow-outline-teal form-input"
                type="text" placeholder="Search here..." aria-label="Search" />
            </div>
          </div>
          <ul class="flex items-center flex-shrink-0 space-x-6">
            <!-- Notifications menu -->
            <li class="relative">
              <router-link to="/vendor/messages" class="relative align-middle rounded-md focus:outline-none focus:shadow-outline-teal" aria-haspopup="true">
                <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z">
                  </path>
                </svg>
                <!-- Notification badge -->
                <span aria-hidden="true"
                  class="absolute top-0 right-0 inline-block w-3 h-3 transform translate-x-1 -translate-y-1 bg-red-600 border-2 border-white rounded-full dark:border-gray-800"></span>
              </router-link>
            </li>
            <!-- Messages menu -->
            <li class="relative">
              <router-link to="/vendor/messages"
                class="relative align-middle rounded-md focus:outline-none focus:shadow-outline-teal"
                aria-haspopup="true">
                <EnvelopeIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
              </router-link>
            </li>
            <!-- Profile menu -->
            <li class="relative">
              <button class="align-middle rounded-full focus:shadow-outline-teal focus:outline-none"
                @click="toggleProfileMenu" @keydown.escape="closeProfileMenu" aria-label="Account" aria-haspopup="true">
                <img class="object-cover w-8 h-8 rounded-full" :src=" userInfo?.avatar ? `${ baseUrl }/media/profile/${ userInfo?.avatar }` : '/no-photo.jpg' "
                      crossorigin="anonymous" :alt=" userInfo?.email " loading="lazy"
                    aria-hidden="true" />
              </button>
              <template v-if="options.isProfileMenuOpen">
                <ul x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
                  x-transition:leave-end="opacity-0" @click="closeProfileMenu" @keydown.escape="closeProfileMenu"
                  class="absolute right-0 w-56 p-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md dark:border-gray-700 dark:text-gray-300 dark:bg-gray-700"
                  aria-label="submenu">
                  <li class="flex">
                    <router-link
                      class="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                      to="/vendor/profile">
                      <svg class="w-4 h-4 mr-3" aria-hidden="true" fill="none" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                        <path d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                      </svg>
                      <span>Profile</span>
                    </router-link>
                  </li>
                  <li class="flex">
                    <router-link
                      class="inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800 dark:hover:bg-gray-800 dark:hover:text-gray-200"
                      to="/accounts/logout">
                      <svg class="w-4 h-4 mr-3" aria-hidden="true" fill="none" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                        <path
                          d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1">
                        </path>
                      </svg>
                      <span>Log out</span>
                    </router-link>
                  </li>
                </ul>
              </template>
            </li>
          </ul>
        </div>
      </header>
      <slot />
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from "@/store/useAuth";
import { reactive } from 'vue'

import {
  ShoppingBagIcon,
  ScaleIcon,
  TruckIcon,
  BanknotesIcon,
  UserCircleIcon,
  HomeIcon,
  EnvelopeIcon,
  CheckBadgeIcon,
  LockClosedIcon,
  Squares2X2Icon,
  NewspaperIcon,
  PowerIcon,
  ArrowRightOnRectangleIcon
} from '@heroicons/vue/24/outline'

const { user: userInfo } = useAuthStore()
// const { userInfo } = storeToRefs(authStore)
const baseUrl = process.env.VUE_APP_API_URL
 
  const options = reactive({
    sidebarOpen: false,
    isSideMenuOpen: false,
    isProfileMenuOpen: false,
    isCollapsed: true,
  })

/* menu items */
const menuItems = !userInfo?.isActivated
  ? [ { name: 'Dashboard', icon: HomeIcon, to: 'pending' }, ] :
  [
    { name: 'Dashboard', icon: HomeIcon, to: 'dashboard' },
    { name: 'Purchase Orders', icon: NewspaperIcon, to: 'purchase-orders' },
    { name: 'RFx Events', icon: ShoppingBagIcon, to: 'rfx-events' },
    { name: 'Auctions', icon: ScaleIcon, to: 'auctions' },
    { name: 'Contracts', icon: CheckBadgeIcon, to: 'contracts' },
    { name: 'Deliveries & D.Notes', icon: TruckIcon, to: 'deliveries' },
    { name: 'Invoices', icon: BanknotesIcon, to: 'invoices' },
    { name: 'Catalogue', icon: Squares2X2Icon, to: 'catalogue' },
    { name: 'User Accounts', icon: UserCircleIcon, to: 'users' },
  ]

const toggleSideMenu = () => options.isSideMenuOpen = !options.isSideMenuOpen
const closeSideMenu = () => options.isSideMenuOpen = false
const toggleProfileMenu = () => options.isProfileMenuOpen = !options.isProfileMenuOpen
const closeProfileMenu = () => options.isProfileMenuOpen = false
const toggleCollapse = () => options.isCollapsed = !options.isCollapsed

</script>