import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { useErrorsStore } from "@/store/useErrors";

const httpLink = createUploadLink({
	uri: process.env.VUE_APP_API_URL,
	credentials: "include",
})

const errorHandler = onError(({ graphQLErrors }) => {
	if (graphQLErrors) {
		useErrorsStore().$state = {
			message: graphQLErrors[0].message,
			category: graphQLErrors[0].extensions.category,
			fields: graphQLErrors[0].extensions.validation ?? { input: {} },
		};
	}
});

const apolloClient = new ApolloClient({
	link: errorHandler.concat(httpLink),
	cache: new InMemoryCache({
		typePolicies: {
			Query: {
				merge: (_, incoming) => incoming,
			},
		},
	}),
	queryDeduplication: false,

	defaultOptions: {
		watchQuery: {
			fetchPolicy: "cache-and-network",
			errorPolicy: "ignore",
		},
		query: {
			fetchPolicy: "network-only",
			nextFetchPolicy: "cache-and-network",
			errorPolicy: "all",
		},
		mutate: {
			errorPolicy: "all",
		},
	},
});

export default apolloClient;
