import { defineStore } from "pinia";
import apolloClient from "../plugins/apollo";

export const useAuthStore = defineStore("auth", {
	state: () => ({
		loggedIn: localStorage.getItem("loggedIn") ?? false,
		user: JSON.parse(localStorage.getItem("user")) ?? null,
	}),

	actions: {
		async login(payload) {
			this.loggedIn = true;
			this.user = payload;

			localStorage.setItem("loggedIn", true);
			localStorage.setItem("user", JSON.stringify(payload));

			await apolloClient.resetStore();
		},

		async profile(payload) {
			const existing = JSON.parse(localStorage.getItem("user"));
			this.user = { ...existing, ...payload };
			localStorage.setItem("user", JSON.stringify(this.user));

			await apolloClient.resetStore();
		},

		async logout() {
			const response = await (
				await fetch(process.env.VUE_APP_API_URL + "/logout", {
					method: "POST",
					credentials: "include",
					"Content-Type": "application/json",
				})
			).json();

			localStorage.clear();
			this.loggedIn = false;
			this.user = null;

			return response
		},
	},

	getters: {
		userInfo: (state) => state.user,
	},
});
