import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/store/useAuth";
import AgencyRoutes from './agency'
import VendorRoutes from './vendor'
import AuthRoutes from './auth'

const routes = [
	...AgencyRoutes,
	...VendorRoutes,
	...AuthRoutes,]

routes.push(
	/* catch-all */
	{
		path: "/:pathMatch(.*)*",
		component: () => import("@/pages/404.vue"),
		meta: { title: "404" },
	}
);

const router = createRouter({
	history: createWebHistory(),
	routes,
	linkActiveClass: "text-teal-400 font-bold",
	linkExactActiveClass: "text-teal-400 font-bold",
});

router.beforeEach((to, _, next) => {
	(to.meta.requireAuth && !useAuthStore().loggedIn) ? next({ path: "/" }) : next();
	window.document.title = 'CROMARFx' + (to.meta && to.meta.title ? ` | ${to.meta.title}` : '')
});

export default router;
